.Collapse {

  &__heading {
    padding: 8px 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #BBC3CF 1px solid;
    cursor: pointer;
    user-select: none;
  }

  &__icon {
    zoom: 1.5;
    transition: all 0.3s ease-in-out;

    &--active {
      transform: rotate(-180deg);
    }
  }

  &__body {
    max-height: 0;
    overflow: hidden;

    &--active {
      overflow: visible;
      max-height: max-content;
    }
  }
}