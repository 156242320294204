.OCCTab {
  &__content {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1040px) {
      flex-direction: column;
      gap: 20px;
    }

    &__table {
      width: max-content;
    }
  }

  &__period_selector {
    margin-top: 20px;
  }

  &__collapse-wrapper {
    margin-top: 32px;
  }

  &__date-input-wrapper {
    margin-top: 16px;
    width: max-content;
    position: relative;

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

}

.OCTWForm {
  padding: 16px;
  border-radius: 6px;
  background: #E6EBF2;
  width: 220px;
  height: max-content;

  h3 {
    margin: 0;
  }

  &__input {
    margin-top: 16px;
    display: block;
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    gap: 14px;
  }
}
