$color-white: #fff;
$color-background-neutral-weakest: #f5f7fa;
$color-border-neutral-weak: #dde1e7;
$color-text-neutral: #3c3c46;
$color-text-neutral-weakest: #8a8a90;

$color-border-secondary: #0a6e82;

$colour-apm-orange: #ff6441;
$colour-grey: #dee2e5;
$colour-light-grey: #f5f7fa;
$colour-light-grey-1: #bbc3cf;
$colour-light-grey-2: #8a8a90;
$colour-light-grey-3: #63636b;
$colour-light-grey-4: #aab4c3;
$colour-light-grey-5: #dfe2e7;
$colour-dark-grey: #3c3c46;
$color-dark-grey: #878787;
$color-dark-grey2: #878787;
$colour-text-error: #b80012;
$colour-text-warning: #ffd029;
$colour-text-success: #328529;

$color-primary-blue-600: #0073ab;
$color-primary-blue-600-darkest: #005487;
$color-primary-blue-600-brightest: #b5e0f5;
$primary-blue-darkest: #00243d;
$color-sky-blue: #00a7ff;
$color-light-blue: #9dc5cd;

$color-primary-orange: #ff6000;
$color-primary-orange-darkest: #cc4000;
$color-primary-orange-brightest: #ff7333;

$color-heatmap-normal: #f5f7fa;
$color-heatmap-low-risk: #fff1c2;
$color-heatmap-high-risk: #fcdde0;

$color-white: #ffffff;

$color-moves-load-full-bar: #0a6e82;
$color-moves-load-empty-bar: #68a5b2;
$color-moves-discharge-full-bar: $color-primary-orange;
$color-moves-discharge-empty-bar: #fb9c64;

$color-black: #000;
$color-grey: #bababa;
$color-white: #fff;
$color-orange: #ff6441;
$color-orange2: #ff6000;
$color-red: #b80012;
$color-light-orange: #ffefeb;
$color-light-orange2: #ffaf9d;
$color-grey: grey;
$color-grey-100: #f7f7f7;
$color-grey-400: #cfcfcf;
$color-grey-600: #878787;
$color-light-grey: #e9e9e9;
$color-light-grey2: #f0eeee;
$color-light-grey3: #f1f1f1;
$color-light-grey4: #dde1e7;
$color-light-grey5: #f5f7fa;
$color-dark-grey: #737373;
$color-x-dark-grey: rgb(84, 84, 84);
$color-dark-grey-low-opacity: #999999;
$color-dark-turquoise: #2f3738;
$color-sky-blue: #00a7ff;
$color-sky-blue-1: #42b0d5;
$color-purple: #7066e0;
$primary-blue-darkest: #00243d;
$primary-blue-desaturated: #3b4f62;

:root {
  --mds_brand_appearance_primary_default_background-color: #ff6000;
  --mds_brand_appearance_primary_default_border-color: #ff6000;
  --mds_brand_appearance_primary_default_text-color: #ff6000;
  --mds_brand_appearance_state_primary_default_hover_border-color: #ff6000;
  --mds_brand_appearance_state_neutral_default_hover_background-color: #FFEFE6;
  --mds_brand_appearance_state_primary_weak_hover_background-color: #FFEFE6;
  --mds_brand_appearance_state_primary_default_hover_background-color: #FF9100;
  --mds_brand_appearance_state_secondary_weak_hover_background-color: #ECF3F5;
  --mds_brand_appearance_state_secondary_default_hover_background-color: #43798B;
  --mds_brand_appearance_secondary_default_border-color: #0A6E82;
  --mds_brand_appearance_secondary_default_background-color: #0A6E82;
  --mds_brand_appearance_secondary_default_text-color: #0A6E82;
}
