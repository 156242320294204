.EditOccValuesDrawer {
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  &__form {
    display: flex;
    gap: 1rem;
  }
  &__field {
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    & > div {
      color: red;
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    background: $colour-light-grey;
    padding: 24px;
    gap: 12px;
    margin: 32px -24px -24px;
  }
}
